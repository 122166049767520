<template>
<Card>
    <template #content>
	<div>        		
		<p>Los cargos han sido emitidos con éxito. Si desea enviar los cargos por email presione el botón “Enviar y Finalizar”, ubicado en la esquina izquierda de la pantalla.</p>		
		<Divider />      		  
        <DataTable :value="cargosEmitidos" editMode="cell" dataKey="id_movimiento" v-model:selection="selectedCargos" :rowClass="rowClass" v-model:expandedRows="expandedRows" responsiveLayout="scroll" class="p-datatable-sm"
		filterDisplay="menu">
			<template #header>
				<div class="table-header p-d-flex p-flex-column p-flex-md-row p-jc-md-between">							
					<div class="p-input-icon-right">
						<Button label="Finalizar" icon="pi pi-check" class="p-button-success" @click="finalizar"/>
					</div>		
					<div class="p-input-icon-right">
						<Button label="Enviar Emails y Finalizar" icon="pi pi-envelope" class="p-button-secondary" @click="dlgEnvioEmail=true" :disabled="!puedeEnviarEmails"/>
					</div>							
				</div>
			</template>		
			<template #empty>
				No hay cargos
			</template>		
			<Column selectionMode="multiple" headerStyle="width: 3rem"/>
            <!-- <Column field="emitir" headerStyle="width: 3em">
				<template #header>
					<i class="pi pi-cog"/>
				</template>					
				<template #body="slotProps">
					<Checkbox v-bind:id="slotProps.data.id_movimiento" v-model="slotProps.data[slotProps.column.props.field]" :binary="true" :disabled="slotProps.data.emitido"/>			
				</template>
			</Column>              -->
            <Column field="cta_habiente" header="Inmueble"/>             
            <!-- <Column field="vigencia_final" header="Vencimiento"/>                    -->
            <!-- <Column field="plantilla" header="Plan"/> -->
			<Column field="movimiento_tipo" header="Tipo"/>
			<Column field="referencia" header="Referencia"/>
            <Column field="doc_fecha" header="Fecha Emisión"/>                             
            <Column field="doc_emision" header="Documento"/>                                      
            <Column field="total" header="Total"> 
				<template #body="slotProps" sortable>
                   	<div class="p-d-flex p-jc-end">
                        <div>{{slotProps.data.id_moneda}} {{formatCurrency(slotProps.data.total)}}</div>
                    </div>   					
				</template>
			</Column>	
            <!-- <Column field="enviar_notificacion" headerStyle="width: 3em">
				<template #header>
					<i class="pi pi-envelope"/>
				</template>			
				<template #body="slotProps">
					<Checkbox  v-bind:id="slotProps.data.enviar_notificacion" v-model="slotProps.data[slotProps.column.props.field]" :binary="true" :disabled="slotProps.data.emitido"/>			
				</template>
			</Column>    								     -->
			<ColumnGroup type="footer">
				<Row>
					<Column footer="Documentos:" :colspan="3" footerStyle="text-align:right" />
					<Column :footer="cargosEmitidos.length"/>
					<Column footer="Total:" :colspan="2" footerStyle="text-align:right" />
					<Column :footer="formatCurrency(totalCargosEmitidos)" footerStyle="text-align:right"/>
				</Row>
			</ColumnGroup>					
        </DataTable>				
    </div>
	</template>
</Card>

<Dialog v-model:visible="dlgEnvioEmail" :modal="true">
	<template #header>
		Confirmación de envío de notificación
	</template>
	<div class="p-d-flex">
		<i class="pi pi-envelope p-mr-2" style="fontSize: 2rem"></i>
		<p>¿Está seguro que desea enviar los documentos seleccionado(s)?</p>
	</div>
	<template #footer>
		<div class="p-field p-col">
			<Button label="Cancelar" icon="pi pi-times" class="p-button-text" @click="dlgEnvioEmail=false"/>
			<Button label="Enviar" icon="pi pi-send" class="p-button-text" @click="enviarACtahabientesSeleccionados"/>
		</div>
	</template>
</Dialog>	

<Dialog v-model:visible="enviandoEmailsDlg" :showHeader="true" :modal="true" :closable="false">			
	<div class="flex align-content-center flex-wrap card-container" :style="{width: '500px', height: '250'}">
		<div class="p-d-flex p-ai-center p-jc-center">
			<!-- <i class="pi pi-send p-m-2" style="font-size: 2rem"></i> -->
			<img src="/assets/layout/images/message.gif" width="64" height="64"/>
		</div>
		<div class="flex align-items-center justify-content-center mt-5">				
		<ProgressBar :value="secuencia">										
			<div class="font-light">
			Enviando {{secuencia}} de {{selectedCargos.length}}
			</div>
		</ProgressBar>
		</div>
	</div>
</Dialog>
</template>
<script>
import MdsService from '../../service/mds.service';
import InvService from '../../service/inv.service';
import { CURRENT_APP_MODE } from '../../service/constants';
import Divider from 'primevue/divider';

export default {
    name: 'MdsEmisionResumen',
    components: {
        Divider
    },    
	props: {
		formData: {
			type: Object,
			Default() {
				return {
					id: '',
					id_mov_plantilla: '0',
					id_cta_habiente_info: '',
					id_categoria: '',
					tasa_cambio: 7.82,
					fecha_emision: new Date(),
					cambiar_fecha_pago: false,
					fecha_pago: new Date(),
					cargosEmitidos: null,
					Param1: '',
					Param2: '',
					Param3: '',
					Param4: '',
					Param5: '',
					Param6: '',							
				}
			}
		}
	},          
    data() {        
        return {         
            appMode: CURRENT_APP_MODE,
			complejo: this.$store.state.auth.currentAppCtx.complejo,
			idMoneda: this.$store.state.auth.currentAppCtx.monedaBase.id_moneda,   			
			id: this.formData.id,
			id_mov_plantilla: this.formData.id_mov_plantilla,
			id_cta_habiente_info: this.formData.id_cta_habiente_info,
			id_categoria: this.formData.id_categoria,
			tasa_cambio: this.formData.tasa_cambio,
			fecha_emision: this.formData.fecha_emision,
			cambiar_fecha_pago: this.formData.cambiar_fecha_pago,
			fecha_pago: this.formData.fecha_pago,
			cargosEmitidos: this.formData.cargosEmitidos,
			Param1: this.formData.Param1,
			Param2: this.formData.Param2,
			Param3: this.formData.Param3,
			Param4: this.formData.Param4,
			Param5: this.formData.Param5,
			Param6: this.formData.Param6,		            
			trxs: null,
			secuencia: 1,
			selectedCargos: null,
			calculandoDlg: false,
			procesandoDlg: false,
			cargos: [],
			gran_total: 0.0,
            cargosAEmitir: null,
			dlgEnvioEmail: false,
			enviandoEmailsDlg: false,
			expandedRows: [],
        }
    },
	computed:{
		totalTrxAEmitir()
		{
			let movs_a_emitir = [];
			if(this.cargosEmitidos) {			
				movs_a_emitir = this.cargosEmitidos.filter(element => (element.emitir && !element.emitido));
			}
			return movs_a_emitir;
		},		
		totalCargosEmitidos(){
			let totalEmitido = 0;
			if(this.cargosEmitidos) {							
				this.cargosEmitidos.forEach(item=> totalEmitido = totalEmitido + item.total)
			}
			return totalEmitido;
		},
		puedeEnviarEmails() {
			if(this.selectedCargos) {
				return (this.selectedCargos.length > 0)
			}  else {
				return false;
			}
		}
	},    
    methods: {
        onRowSelect(data) {
            data.excluir = data.excluir == 0? 1: 0;						
			return (data.excluir == 1);			
        },
		rowClass(data)
		{
			var className = (data.emitir?"emitir-row":"regular-row");
			return className;
		},
		formatCurrency(value) {
			return parseFloat(value).toLocaleString('en-US', {minimumFractionDigits: 2});
		},	        
		finalizar() {
			this.$router.push('/');
		},
		prevPage() {
            this.$emit('prevPage', {pageIndex: 4});
        },
        nextPage(){
            this.$emit('nextPage', {pageIndex: 4});
        },
		async enviarACtahabientesSeleccionados()
		{
			this.dlgEnvioEmail	= false;
			try
			{
				this.enviandoEmailsDlg = true;
				this.secuencia = 1;
				var notif;

				for(var i = 0; i < this.selectedCargos.length; i++)
				{												
					notif = {						
						id: this.selectedCargos[i].id_movimiento,
						emails: []
					};

					notif.emails =  await MdsService.getInmueblesEmailsNotificacion(this.$store.state.auth.currentAppCtx, this.selectedCargos[i].id_cta_habiente_info);

					if(notif.emails.length > 0) {
						await InvService.enviarEmailTrx(this.$store.state.auth.currentAppCtx, notif);
					}

					this.secuencia++;
				}
				this.secuencia = 1;
				this.enviandoEmailsDlg = false;
				this.finalizar();
			}
			catch(error) {
				this.dlgEnvioEmail = false;
				console.log(error);
			}
		},
    }
}
</script>

<style scoped lang="scss">
::v-deep(b) {
    display: block;
}

::v-deep(.p-card-body) {
    padding: 2rem;
}

::v-deep(.regular-row) {
	font-weight: 300;
	background: white;
}

::v-deep(.emitir-row) {
	font-weight: bold;
	background:white;
}

</style>